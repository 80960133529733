<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="1000px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
  >
    <batch ref="batch" @getbatch="getbatch" :titleName="titleName" :isDialog="true"></batch>
  </el-dialog>
</template>
<script>
  import batch from '@/views/common/batch.vue'
  export default {
    name: 'batchList',
    components: {
      batch
    },
    props:{
      titleName: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        closeFlag: false,
        loading: false,
        title: '产品批次档案选择',
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
      initData(){
        this.closeFlag = true
        this.$refs.batch && this.$refs.batch.reTable()
      },
      closeModel() {
        this.closeFlag = false
      },
      getbatch(data){
        this.$emit('getbatchlist', data)
        this.closeModel()
      }
    },
  }
</script>
<style lang="scss" scoped>
</style>